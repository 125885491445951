import { useContext, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";

import { useLazyUploadManualResultQuery } from "../../../Redux/services/endpoints/transcriptManagement";
import { Button, Card, Select, TextInput } from "../../../UI";
import { TabPage } from "../../../components";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";

const Upload = () => {
  const { uploadFile } = useContext(GeneralContext);
  const [formData, setFormData] = useState({});
  const [documentName, setDocumentName] = useState("");
  const [uploadType, setUploadType] = useState(1);

  const [trigger] = useLazyUploadManualResultQuery();

  const { getReadyForDispatchTranscripts, getRequireReviewTranscripts } =
    useContext(TranscriptManagementContext);

  const onFileChange = async (e) => {
    const { files, name } = e.target;
    const { data } = await uploadFile(files[0]);
    // console.log(data);
    setFormData((prev) => ({ ...prev, [name]: data }));
    setDocumentName(files[0].name);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectUploadType = (e) => {
    const { name, value } = e.target;
    setUploadType(value);
    setFormData((prev) => ({ ...prev, [name]: value === 2 }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Remove null or undefined values from formData
    const cleanedFormData = Object.fromEntries(
      Object.entries(formData).filter(
        ([_, value]) => value !== (null || undefined)
      )
    );

    trigger(cleanedFormData);
    setFormData({});
    setDocumentName("");
    getReadyForDispatchTranscripts(uploadType === 2);
    getRequireReviewTranscripts(uploadType === 2);
  };

  return (
    <TabPage pageTitle="Transcript Management/ Upload Transcript">
      <Card>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <TextInput
            placeholder="Enter Transcript Request Order Number"
            required
            name="RequestOrderNumber"
            onChange={handleInput}
            value={formData.RequestOrderNumber || ""}
          />
          <Select
            options={[
              {
                key: 1,
                value: "Transcript",
              },
              {
                key: 2,
                value: "Proficiency In English",
              },
            ]}
            placeholder="Select type"
            required
            onChange={handleSelectUploadType}
            name="isProficiency"
          />
          <div className="flex flex-col gap-3 w-full relative">
            <p className="text-sm">
              Upload Scanned Transcript Document (optional):
            </p>
            <label
              htmlFor="transcriptDocument"
              className="bg-primary p-2 rounded-md flex items-center text-white gap-3 w-fit"
            >
              <HiOutlineUpload /> Upload file
            </label>
            <input
              type="file"
              id="transcriptDocument"
              onChange={onFileChange}
              name="resultUrl"
              className="absolute bottom-0 -z-[1] opacity-0"
            />
            <p className="text-xs">{documentName}</p>
          </div>
          <div>
            <Button type="submit">Save</Button>
          </div>
        </form>
      </Card>
    </TabPage>
  );
};

export default Upload;
