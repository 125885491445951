import EditIcon from "@mui/icons-material/Edit";
import React, { useContext, useState } from "react";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";

import { Button } from "../../../UI";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptRequestContext } from "../../../contexts/transcriptRequest";
import {
  ResultRequestType,
  ResultType,
  TRANSCRIPT_USE_CASE_ENUM,
} from "../../../utils/helper";
import classes from "./DestinationInfo.module.css";
import moment from "moment"

export const ProvidedInformation = ({ onClick, handleBack }) => {
  const [isPending, setIsPending] = useState(false);
  const { countries, states, cities, initializePaymentSchema } =
    useContext(GeneralContext);

  const {
    transcriptInfo,
    transcriptOption,
    copyType,
    createTranscript,
    setTranscriptOrderNo,
    requestType,
  } = useContext(TranscriptRequestContext);

  //handle next button click
  const handleNext = async () => {
    setIsPending(true);
    try {
      //update values of transcript data
      transcriptInfo.regNumber = localStorage.regNumber;
      transcriptInfo.studentId = localStorage.studentId;
      transcriptInfo.status = 1;
      transcriptInfo.requestType = 1;
      transcriptInfo.resultRequestType = ResultRequestType[requestType];
      transcriptInfo.resultType = ResultType[copyType];
      transcriptInfo.trancriptUseCaseType =
        TRANSCRIPT_USE_CASE_ENUM[transcriptOption];

      const orderNo = await createTranscript(transcriptInfo);
      setTranscriptOrderNo(orderNo);

      //update initilaize payment data
      initializePaymentSchema.RequestOrderNumber = orderNo;
      initializePaymentSchema.feeType = 1;
      initializePaymentSchema.resultType = ResultType[copyType];
      setIsPending(false);
      onClick();
    } catch (err) {
      toast.error("Something went wrong, please try again");
      setIsPending(false);
    }
  };

  // handle back button click
  const handleBackBtn = () => {
    handleBack((prevStep) => prevStep - 1);
  };

  //helper variables for generating values
  const country = countries.filter(
    (p) => p.key === Number(transcriptInfo.countryId)
  )[0]?.value;
  const state = states.filter(
    (p) => p.key === Number(transcriptInfo.stateId)
  )[0]?.value;
  const city = cities.filter((p) => p.key === Number(transcriptInfo.cityId))[0]
    ?.value;

  return (
    <div>
      <p className={classes.providedInformation__heading}>
        Destination Information
      </p>
      <p className={classes.providedInformation__subHeading}>
        Let's be sure... review your information
      </p>
      <div className={classes.providedInformation__container}>
        <ul className={classes.providedInformation__unorderedList}>
          <li>
            <p>Institution/Organisation</p>
            <h4>{transcriptInfo.organisation}</h4>
          </li>
          <li>
            <p>Address</p>
            <h4>{transcriptInfo.address1}</h4>
          </li>
          <li>
            <p>Continent</p>
            <h4>Africa</h4>
          </li>
          <li>
            <p>Country</p>
            <h4>{country}</h4>
          </li>
          <li>
            <p>State/Province</p>
            <h4>{state}</h4>
          </li>
          <li>
            <p>Reference Number</p>
            <h4>{transcriptInfo.wesReferenceNumber}</h4>
          </li>
        </ul>
        <ul className={classes.providedInformation__unorderedList}>
          <li>
            <p>City</p>
            <h4>{city}</h4>
          </li>
          <li>
            <p>Zip/PostCode</p>
            <h4>{transcriptInfo.postalCode}</h4>
          </li>
          <li>
            <p>Destination Email</p>
            <h4>{transcriptInfo.destinationEmail}</h4>
          </li>
          <li>
            <p>Date of Birth</p>
            <h4>{moment(transcriptInfo.dateOfBirth).format("Do MMM YYYY")}</h4>
          </li>
          {transcriptInfo.referenceNumber ? (
            <li>
              <p>Reference Number</p>
              <h4>{transcriptInfo.referenceNumber}</h4>
            </li>
          ) : null}

          <li>
            <p>Purpose</p>
            <h4>{transcriptOption}</h4>
          </li>
          {copyType ? (
            <li>
              <p>Copy</p>
              <h4>{copyType}</h4>
            </li>
          ) : null}
        </ul>

        <div
          onClick={handleBackBtn}
          className={classes.providedInformation__editButton}
        >
          <EditIcon
            style={{
              color: "white",
              fontSize: "20px",
            }}
          />
        </div>
      </div>

      <div className="flex gap-8 justify-center">
        <Button onClick={handleBackBtn} variant="invert" className="flex gap-2">
          <HiOutlineArrowSmLeft size={20} />
          Back
        </Button>
        <Button onClick={handleNext} className="flex gap-2">
          {isPending ? (
            <ImSpinner3 className="animate-spin" size={22} />
          ) : (
            <>
              Next <HiOutlineArrowSmRight size={20} />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
