import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { toast } from "react-toastify";

import { Button, Select, TextInput } from "../../../UI";
import InputPhone from "../../../UI/PhoneInput/PhoneInput";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptRequestContext } from "../../../contexts/transcriptRequest";
import classes from "./DestinationInfo.module.css";

export const Form = ({ onSubmit, handleBack }) => {
  const [stateCityRequired, setStateCityRequired] = useState(true);
  const { countries, getCountries, getStates, getCities, states, cities } =
    useContext(GeneralContext);
  const { transcriptInfo, setTranscriptInfo, transcriptOption } = useContext(
    TranscriptRequestContext
  );

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const validatePhoneNumber = (phoneNumber) => {
    if (
      !transcriptInfo.destinationContactNumber ||
      !isValidPhoneNumber(phoneNumber)
    ) {
      toast.error("Please enter a valid number");
      return false;
    }
    return true;
  };

  //handle updating transcript data on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "postalCode") {
      setTranscriptInfo((prev) => ({ ...prev, [name]: String(value) }));
    } else {
      setTranscriptInfo((prev) => ({ ...prev, [name]: value }));
    }
    switch (name) {
      case "countryId":
        getStates(value);
        if (Number(value) === 160) {
          setStateCityRequired(true);
        } else {
          setStateCityRequired(false);
        }
        break;
      case "stateId":
        getCities(value);
        break;
      default:
        return;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(transcriptInfo.destinationContactNumber))
      onSubmit((prevStep) => prevStep + 1);
  };

  return (
    <form onSubmit={submitHandler} className="flex flex-col gap-6">
      <div className="flex gap-5 flex-col">
        <div className="flex flex-col gap-8">
          <div className="flex gap-4 w-full">
            {transcriptOption === "Official Use" && (
              <TextInput
                placeholder="Instituition/Organization"
                inputClassName={classes.form__fullfWidth}
                name="organisation"
                value={
                  transcriptInfo.organisation ? transcriptInfo.organisation : ""
                }
                onChange={handleInputChange}
                required
              />
            )}
            <TextInput
              placeholder="Address1"
              inputClassName={classes.form__fullWidth}
              name="address1"
              value={transcriptInfo.address1 ? transcriptInfo.address1 : ""}
              onChange={handleInputChange}
              required
              helperText={
                transcriptOption === "Official Use"
                  ? "We don't send official transcript to personal businesses or home addresses"
                  : ""
              }
            />
          </div>
          <div className="flex gap-4 w-full">
            <TextInput
              placeholder="Address2"
              inputClassName={classes.form__fullWidth}
              name="address2"
              value={transcriptInfo.address2 ? transcriptInfo.address2 : ""}
              onChange={handleInputChange}
            />
            <TextInput
              placeholder="Destination Email"
              inputClassName={classes.form__fullWidth}
              type="email"
              name="destinationEmail"
              value={
                transcriptInfo.destinationEmail
                  ? transcriptInfo.destinationEmail
                  : ""
              }
              onChange={handleInputChange}
              required
              helperText={
                transcriptOption === "Official Use"
                  ? "We don't send official transcript to personal emails. We also watermark the transcript with the mailing addresses"
                  : ""
              }
            />
          </div>
          <div className="flex gap-4 w-full">
            <TextInput
              placeholder="Date of Birth"
              inputClassName={classes.form__fullWidth}
              type="date"
              name="dateOfBirth"
              value={
                transcriptInfo.dateOfBirth ? transcriptInfo.dateOfBirth : ""
              }
              onChange={handleInputChange}
              required
            />

            <InputPhone
              name="destinationContactNumber"
              onChange={handleInputChange}
              value={
                transcriptInfo.destinationContactNumber
                  ? transcriptInfo.destinationContactNumber
                  : ""
              }
            />
          </div>
        </div>
        <div className="flex gap-4 w-full">
          <Select
            inputClassName={classes.form__halfWidth}
            placeholder="Country"
            options={countries}
            name="countryId"
            onChange={handleInputChange}
            required
            value={transcriptInfo.countryId ? transcriptInfo.countryId : ""}
          />
          <Select
            inputClassName={classes.form__halfWidth}
            placeholder="State"
            options={states}
            name="stateId"
            onChange={handleInputChange}
            required
            value={transcriptInfo.stateId ? transcriptInfo.stateId : ""}
          />
        </div>
        <div className="flex gap-4 w-full">
          <Select
            inputClassName={classes.form__halfWidth}
            placeholder="City"
            options={cities}
            name="cityId"
            onChange={handleInputChange}
            required
            value={transcriptInfo.cityId ? transcriptInfo.cityId : ""}
          />

          <TextInput
            placeholder="ZIP/Postal Code"
            inputClassName={classes.form__halfWidth}
            name="postalCode"
            value={transcriptInfo.postalCode ? transcriptInfo.postalCode : ""}
            onChange={handleInputChange}
            required
          />
        </div>
        <TextInput
          placeholder="Reference Number"
          inputClassName={classes.form__halfWidth}
          name="wesReferenceNumber"
          value={
            transcriptInfo.wesReferenceNumber
              ? transcriptInfo.wesReferenceNumber
              : ""
          }
          onChange={handleInputChange}
        />
      </div>
      <div className="flex gap-8 justify-center">
        <Button onClick={handleBack} variant="invert" className="flex gap-2">
          <HiOutlineArrowSmLeft size={20} />
          Back
        </Button>
        <Button type="submit" className="flex gap-2">
          Next <HiOutlineArrowSmRight size={20} />
        </Button>
      </div>
    </form>
  );
};
